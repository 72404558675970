import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from "../components/seo"
import Team from '../components/sections/Team'
import Header from '../components/sections/Header'
import ImageLeft from '../components/sections/ImageLeft'
import Cta from '../components/sections/Cta'


const team = () => {
    return (
        <Layout>
            <Seo title="Our Team" />
            <Header />
            <ImageLeft heading="Meet the Team" content="Add content here - team based, description of experience, etc. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi, repellendus quis! Dolor voluptatem dolorem necessitatibus blanditiis amet repudiandae a quae eum, dolores, dignissimos qui iste, officia fugiat similique facere consequuntur.
" />
            <Team />
            <Cta />
        </Layout>
    )
}

export default team
